import React from 'react';
import { func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockContainer from '../BlockContainer';
import { ExternalLink } from '../../../../components';

import css from './BlockFooter.module.css';
import defaultConfig from '../../../../config/configDefault';
import { FormattedMessage } from 'react-intl';

const BlockFooter = props => {
  const { blockId, className, rootClassName, textClassName, text, options, index } = props;
  const classes = classNames(rootClassName || css.root, className);
  const hasTextComponentFields = hasDataInFields([text], options);

  return (
    <BlockContainer id={blockId} className={classes}>
      {index === 1 ? (
        <div className={classNames(textClassName, css.email)}>
          <ExternalLink
            key="emailStudioscout"
            title="Email Studio Scout"
            href={defaultConfig.siteEmailCta}
          >
            <FormattedMessage id="General.contactUs" />
          </ExternalLink>
        </div>
      ) : null}
      {hasTextComponentFields ? (
        <div className={classNames(textClassName, css.text)}>
          <Field data={text} options={options} />
        </div>
      ) : null}
    </BlockContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

BlockFooter.defaultProps = {
  className: null,
  rootClassName: null,
  textClassName: null,
  text: null,
  options: null,
};

BlockFooter.propTypes = {
  blockId: string.isRequired,
  className: string,
  rootClassName: string,
  textClassName: string,
  text: object,
  options: propTypeOption,
};

export default BlockFooter;
